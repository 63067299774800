import './public/styles/main.scss';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import theme from './Theme';
import { ChakraProvider } from '@chakra-ui/react';
import { BrowserRouter as Router } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('app'));
root.render(
    <React.StrictMode>
        <ChakraProvider theme={theme}>
            <Router>
                <App />
            </Router>
        </ChakraProvider>
    </React.StrictMode>
);
