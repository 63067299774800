import { extendTheme } from "@chakra-ui/react";
import { mode, StyleFunctionProps } from "@chakra-ui/theme-tools";

const theme = extendTheme({
    styles: {
        global: (props: Record<string, any> | StyleFunctionProps) => ({
            body: {
                bg: mode('#f7f8fd', 'gray.800')(props),
            }
        })
    },
})

export default theme
